import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGetList } from "react-admin";

const MonthlyPerformanceTable = ({ filter }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const { data, isLoading, error } = useGetList("dashboardCards&action=monthlyPerformanceWidget", {
        filter,
    });

    useEffect(() => {
        if (!data || data.length === 0) return;

        // Extract dynamic month columns from the first row of data
        const dynamicColumns = Object.keys(data[0])
            .filter((key) => key !== "row_name" && key !== "total_result" && key !== "trend" && key !== "id")
            .map((key) => ({
                field: key,
                headerName: key.substring(0, 3), // Optional: format month names
                flex: 1, // Adjust column width based on available space
                minWidth: 50, // Ensure a minimum width
                type: "number",
                sortable: false,
            }));

        // Map rows to the required format
        const rowData = data.map((item, index) => ({
            id: index, // Use index as ID (ensure unique key)
            ...item,
        }));
        rowData.forEach((rdata) => {
            Object.keys(rdata).forEach((key) => {
                if (rdata[key] === null) {
                    rdata[key] = 0;
                } else {
                    rdata[key] = isNaN(parseFloat(rdata[key])) ? rdata[key] : parseFloat(rdata[key]).toFixed(0);
                }
            });
        });
        setColumns([
            {
                field: "row_name",
                headerName: "Row Name",
                width: 150,
                sortable: false,
            },
            ...dynamicColumns,
            {
                field: "total_result",
                headerName: "Total",
                width: 120,
                type: "number",
                sortable: false,
            },
            {
                field: "trend",
                headerName: "Trend (%)",
                width: 120,
                type: "number",
                sortable: false,
                renderCell: (params) => {
                    const value = parseFloat(params.value || 0).toFixed(2);
                    let color;
                    if (value < 0) {
                        color = "red";
                        return (
                            <span
                                style={{
                                    color,
                                    fontWeight: "light",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                {" "}
                                <div
                                    style={{
                                        width: "21px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img style={{ width: "100%" }} src={require("../../images/red_arrow.png")} alt="arrow-icon" />
                                </div>
                                {value}%
                            </span>
                        );
                    } else {
                        color = "green";
                        return (
                            <span
                                style={{
                                    color,
                                    fontWeight: "light",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    marginLeft: "2px",
                                }}
                            >
                                {" "}
                                <img src={require("../../images/gren-arrow.png")} alt="green-arrow" />
                                {value}%
                            </span>
                        );
                    }
                },
            },
        ]);

        setRows(rowData);
    }, [data]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading data</div>;

    return (
        <div>
            <DataGrid
                rows={rows}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                pageSizeOptions={[5]}
                sx={{
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#f5f5f5",
                        fontSize: "0.9rem",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        display: "none",
                    },
                }}
            />
        </div>
    );
};

export default MonthlyPerformanceTable;